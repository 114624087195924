import { html, css, LitElement } from "lit";
import "./components/app-game";

export default class AppMain extends LitElement {
  static properties = {};

  async firstUpdated() {}

  static styles = [
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
    `,
  ];

  render() {
    return html`<app-game></app-game> `;
  }
}

customElements.define("app-main", AppMain);
