import { html, css, LitElement } from "lit";
import "@material/mwc-ripple";

export default class CardButton extends LitElement {
  static properties = {
    label: { type: String },
    hero: { type: String },
    ripple: { type: Object, state: true },
  };

  async firstUpdated() {
    this.ripple = this.shadowRoot?.querySelector("mwc-ripple");
    console.log(this.ripple);
  }

  static styles = [
    css`
      button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 48px);
        height: 65px;
        margin: 24px;
        background: #9e9e9e;
        border: 2px solid #fff;
        border-radius: 32px;
        font-size: 24px;
        color: #f5f5f5;
        font-family: "Archivo Black", sans-serif;
        --mdc-ripple-color: #000;
        overflow: hidden;
      }

      @media (max-width: 1300px) {
        button {
          height: 55px;
          font-size: 20px;
        }
      }

      @media (max-width: 1000px) {
        button {
          height: 45px;
          font-size: 18px;
        }
      }

      button[hero="Blazagon"] {
        background: #ff416c; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #ff4b2b,
          #ff416c
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #ff4b2b,
          #ff416c
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      button[hero="Tyro"] {
        background: #1c92d2; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #9ddce8,
          #1c92d2
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #9ddce8,
          #1c92d2
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      button[hero="Rockjaw"] {
        background: #f79d00; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #64f38c,
          #f79d00
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #64f38c,
          #f79d00
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      button[hero="Orbitox"] {
        background: #ad5389; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #3c1053,
          #ad5389
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #3c1053,
          #ad5389
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      button[hero="Warsnap"] {
        background: #11998e; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #38ef7d,
          #11998e
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #38ef7d,
          #11998e
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      button[hero="Brainboom"] {
        background: #3a1c71; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #ffaf7b,
          #d76d77,
          #3a1c71
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #ffaf7b,
          #d76d77,
          #3a1c71
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      button[hero="Pantaro"] {
        background: #bdc3c7; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #2c3e50,
          #bdc3c7
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #2c3e50,
          #bdc3c7
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      button[hero="Gigatusk"] {
        background: #000046; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #1cb5e0,
          #000046
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #1cb5e0,
          #000046
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
    `,
  ];

  render() {
    return html`
      <button
        hero=${this.hero}
        @mouseenter=${() => this.ripple.startHover()}
        @mouseleave=${() => this.ripple.endHover()}
        @focus=${() => this.ripple.startFocus()}
        @blur=${() => this.ripple.endFocus()}
        @mousedown=${(e) => this.ripple.startPress(e)}
        @mouseup=${() => this.ripple.endPress()}
        @keydown=${(e) => (e.which === 32 ? this.ripple.startPress() : "")}
        @keyup=${() => this.ripple.endPress()}
        ?disabled=${this.disabled}
      >
        <label>${this.label}</label>
        <mwc-ripple primary></mwc-ripple>
      </button>
    `;
  }
}

customElements.define("card-button", CardButton);
