import { html, css, LitElement } from "lit";
import "./hero-adventure-card";

export default class BattleScreen extends LitElement {
  static properties = {
    battle: { type: Object },
    hero: { type: Object },
    isActive: { type: Boolean },
  };

  updated(changedProps) {
    if (changedProps.has("isActive") && this.isActive) {
      console.log("active");
      setTimeout(() => {
        this.style.setProperty("--hero-position-bottom", "170px");
        this.style.setProperty("--hero-position-top", "inherit");
        this.style.setProperty("--hero-position-left", "100px");
        this.style.setProperty("--hero-position-right", "inherit");

        this.style.setProperty("--enemy-position-top", "75px");
        this.style.setProperty("--enemy-position-bottom", "inherit");
        this.style.setProperty("--enemy-position-right", "-200px");
        this.style.setProperty("--enemy-position-left", "inherit");
      }, 2000);

      setTimeout(() => {
        this.dispatchEvent(
          new CustomEvent("reset-game", { composed: true, bubbles: true })
        );
      }, 10000);
    } else {
      console.log("not active");
      this.style.setProperty("--hero-position-bottom", "inherit");
      this.style.setProperty("--hero-position-top", "50%");
      this.style.setProperty("--hero-position-left", "175px");
      this.style.setProperty("--hero-position-right", "inherit");

      this.style.setProperty("--enemy-position-top", "50%");
      this.style.setProperty("--enemy-position-bottom", "inherit");
      this.style.setProperty("--enemy-position-right", "-130px");
      this.style.setProperty("--enemy-position-left", "inherit");
    }
  }

  static styles = [
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      h1 {
        position: absolute;
        left: 24px;
        top: 15px;
        font-size: 32px;
        color: #f5f5f5;
        font-family: "Archivo Black", sans-serif;
        padding: 0;
        margin: 0;
      }

      p {
        position: absolute;
        left: 24px;
        top: 55px;
        font-size: 22px;
        color: #f5f5f5;
        font-family: "Archivo Black", sans-serif;
        padding: 0;
        margin: 0;
      }

      hero-adventure-card[hero] {
        position: absolute;
        left: 175px;
        top: 50%;
        transform: translate(-50%, -50%);
        left: var(--hero-position-left, 175px);
        right: var(--hero-position-right, inherit);
        top: var(--hero-position-top, 50%);
        bottom: var(--hero-position-bottom, inherit);
      }

      hero-adventure-card[enemy] {
        position: absolute;
        right: -130px;
        top: 50%;
        transform: translate(-50%, -50%);
        left: var(--enemy-position-left, inherit);
        right: var(--enemy-position-right, -130px);
        top: var(--enemy-position-top, 50%);
        bottom: var(--enemy-position-bottom, inherit);
      }

      @media (max-width: 768px) {
        h1 {
          font-size: 24px;
          top: 20px;
        }
        p {
          font-size: 16px;
          top: 50px;
        }
      }
    `,
  ];

  render() {
    return html`
      <h1>${this.battle.name}</h1>
      <p>${this.battle.type}</p>

      <hero-adventure-card hero .hero=${this.hero}></hero-adventure-card>

      ${this.battle?.type === "battle"
        ? html`
            <hero-adventure-card
              enemy
              .hero=${this.battle.enemy}
            ></hero-adventure-card>
          `
        : ""}
    `;
  }
}

customElements.define("battle-screen", BattleScreen);
