import { html, css, LitElement } from "lit";

export default class AdventureBattle extends LitElement {
  static properties = {
    event: { type: Object },
  };

  async firstUpdated() {
    this.addEventListener("click", () => {
      this.setSelectedBattle(this.event);
    });
    await this.updateComplete;
    const random = this.randomIntFromInterval(10, 100);
    this.style.setProperty("--random-rotation", random + "px");
    this.style.setProperty("--random-margin", random + "px");
    await this.updateComplete;
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static styles = [
    css`
      :host {
        border: 6px solid #212121;
        border-radius: 50%;
        width: 100px;
        min-width: 100px;
        height: 100px;
        min-height: 100px;
        margin: var(--random-margin);
        -webkit-animation: float 16.8s infinite linear;
        animation: float 16.8s infinite linear;
        background: #ffe259; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to left,
          #ffa751,
          #ffe259
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to left,
          #ffa751,
          #ffe259
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
      :host(:hover) {
        transition: 0.5s ease;
        background: #212121;
      }

      img {
        height: 100px;
      }

      @-webkit-keyframes float {
        from {
          -webkit-transform: rotate(-0.001deg)
            translate3d(var(--random-rotation), 5px, 5px) rotate(-0.001deg);
        }
        to {
          -webkit-transform: rotate(360.001deg)
            translate3d(var(--random-rotation), 5px, 5px) rotate(-360.001deg);
        }
      }
      @keyframes float {
        from {
          transform: rotate(-0.001deg)
            translate3d(var(--random-rotation), 5px, 5px) rotate(-0.001deg);
        }
        to {
          transform: rotate(360.001deg)
            translate3d(var(--random-rotation), 5px, 5px) rotate(-360.001deg);
        }
      }
    `,
  ];

  render() {
    return html` <img src=${this.event.image} /> `;
  }
}

customElements.define("adventure-battle", AdventureBattle);
