import { html, css, LitElement } from "lit";
import "./card-button";

export default class ChampionCard extends LitElement {
  static properties = {
    champion: { type: Object },
  };

  static styles = [
    css`
      :host {
        display: block;
        height: 604px;
        margin: 0px -25px;
        position: relative;
        width: 250px;
      }

      :host(:nth-child(1)) card-shell {
        transform: translateY(45px) rotate(3deg);
      }
      :host(:nth-child(2)) card-shell {
        transform: translateY(45px) rotate(2deg);
      }
      :host(:nth-child(3)) card-shell {
        transform: translateY(45px) rotate(1deg);
      }
      :host(:nth-child(4)) card-shell {
        transform: translateY(45px) rotate(0deg);
      }
      :host(:nth-child(5)) card-shell {
        transform: translateY(45px) rotate(-1deg);
      }
      :host(:nth-child(6)) card-shell {
        transform: translateY(45px) rotate(-2deg);
      }
      :host(:nth-child(7)) card-shell {
        transform: translateY(45px) rotate(-3deg);
      }

      card-shell {
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 800ms cubic-bezier(0.19, 1, 0.22, 1) transform;
        background: white;
        width: 300px;
        display: inline-block;
        margin: auto;
        border-radius: 19px;
        position: relative;
        text-align: center;
        box-shadow: -1px 15px 30px -12px black;
      }

      card-shell:after {
        -webkit-animation: none;
        animation: none;
        background: #fff;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;
      }

      :host card-shell {
        transform: translateY(45px) rotate(2deg);
      }

      :host(:hover) card-shell {
        transform: translateY(-100px) rotate(0deg) scale(1.1);
        transition-duration: 0ms;
        z-index: 5;
      }
      :host(:hover) card-shell:after {
        -webkit-animation: fade 250ms ease-out forwards;
        animation: fade 250ms ease-out forwards;
      }
      :host:hover:after {
        top: -175px;
      }

      @-webkit-keyframes fade {
        0% {
          opacity: 0.9;
          transform: scale(1);
        }
        100% {
          opacity: 0;
          transform: scale(1.15);
        }
      }

      @keyframes fade {
        0% {
          opacity: 0.9;
          transform: scale(1);
        }
        100% {
          opacity: 0;
          transform: scale(1.15);
        }
      }

      /* end */
      card-image {
        display: block;
        position: relative;
        height: 230px;
        margin-bottom: 35px;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }

      card-image {
        background: #ff416c; /* fallback for old browsers */
        overflow: hidden;
      }

      card-image[hero="Blazagon"] {
        background: #ff416c; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #ff4b2b,
          #ff416c
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #ff4b2b,
          #ff416c
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Tyro"] {
        background: #1c92d2; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #9ddce8,
          #1c92d2
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #9ddce8,
          #1c92d2
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Rockjaw"] {
        background: #f79d00; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #64f38c,
          #f79d00
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #64f38c,
          #f79d00
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Orbitox"] {
        background: #ad5389; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #3c1053,
          #ad5389
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #3c1053,
          #ad5389
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Warsnap"] {
        background: #11998e; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #38ef7d,
          #11998e
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #38ef7d,
          #11998e
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Brainboom"] {
        background: #3a1c71; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #ffaf7b,
          #d76d77,
          #3a1c71
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #ffaf7b,
          #d76d77,
          #3a1c71
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Pantaro"] {
        background: #bdc3c7; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #2c3e50,
          #bdc3c7
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #2c3e50,
          #bdc3c7
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Gigatusk"] {
        background: #000046; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #1cb5e0,
          #000046
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #1cb5e0,
          #000046
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image img {
        width: 610px;
        position: absolute;
        top: -185px;
        left: -155px;
      }

      card-power {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 3px;
      }

      card-power[hero="Blazagon"] {
        color: #ff416c;
      }

      card-power[hero="Tyro"] {
        color: #1c92d2;
      }

      card-power[hero="Rockjaw"] {
        color: #f79d00;
      }

      card-power[hero="Orbitox"] {
        color: #3c1053;
      }

      card-power[hero="Warsnap"] {
        color: #11998e;
      }

      card-power[hero="Brainboom"] {
        color: #3a1c71;
      }

      card-power[hero="Pantaro"] {
        color: #2c3e50;
      }

      card-power[hero="Gigatusk"] {
        color: #000046;
      }

      hero-name {
        font-size: 26px;
        color: black;
        font-weight: 900;
        margin-bottom: 5px;
      }

      hero-description {
        padding: 20px;
        margin-bottom: 10px;
        font-size: 12px;
      }

      @media (max-width: 1300px) {
        :host {
          display: block;
          height: 335px;
          margin: 0px -40px;
          position: relative;
          width: 200px;
        }

        :host(:hover) card-shell {
          transform: translateY(-100px) rotate(0deg) scale(1.1);
          transition-duration: 0ms;
          z-index: 5;
        }
      }

      @media (max-width: 1000px) {
        :host {
          display: block;
          height: 295px;
          margin: 0px -45px;
          position: relative;
          width: 172px;
        }

        :host(:hover) card-shell {
          transform: translateY(0px) rotate(0deg) scale(1);
        }

        card-power {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
        }

        hero-name {
          font-size: 21px;
          color: black;
          font-weight: 900;
          margin-bottom: 0px;
        }

        hero-description {
          padding: 0px 20px;
          margin-bottom: 10px;
          font-size: 12px;
        }

        card-image img {
          width: 250px;
          position: absolute;
          top: -64px;
          left: 24px;
        }

        card-image {
          height: 110px;
          margin-bottom: 12px;
        }
      }
    `,
  ];

  render() {
    return html`
      <card-shell>
        <card-image hero=${this.champion.name}>
          <img src=${this.champion?.image} alt="${this.champion.name}" />
        </card-image>
        <card-power hero="${this.champion.name}">
          Level ${this.champion.power}
        </card-power>
        <hero-name>${this.champion?.name}</hero-name>
        <hero-description hidden
          >${this.champion?.description}</hero-description
        >
        <card-button
          @click=${() => {
            this.setSelectedHero(this.champion);
          }}
          .hero=${this.champion.name}
          label="SELECT"
        ></card-button>
      </card-shell>
    `;
  }
}

customElements.define("champion-card", ChampionCard);
