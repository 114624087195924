import { html, css, LitElement } from "lit";
import "./card-button";

export default class HeroAdventureCard extends LitElement {
  static properties = {
    hero: { type: Object },
  };

  firstUpdated() {
    setTimeout(() => {
      this.style.setProperty("--hero-scale", "scale(.5)");
    }, 1500);
  }

  static styles = [
    css`
      :host {
        display: block;
      }

      card-shell {
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 800ms cubic-bezier(0.19, 1, 0.22, 1) transform;
        background: white;
        width: 300px;
        display: inline-block;
        margin: auto;
        border-radius: 19px;
        position: relative;
        text-align: center;
        box-shadow: -1px 15px 30px -12px black;
        transform: var(--hero-scale);
      }

      /* end */
      card-image {
        display: block;
        position: relative;
        height: 140px;
        margin-bottom: 12px;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: #ff416c; /* fallback for old browsers */
        overflow: hidden;
      }

      card-image img {
        width: 280px;
        position: absolute;
        top: -75px;
        left: 10px;
      }

      card-image[hero="Blazagon"] {
        background: #ff416c; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #ff4b2b,
          #ff416c
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #ff4b2b,
          #ff416c
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Tyro"] {
        background: #1c92d2; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #9ddce8,
          #1c92d2
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #9ddce8,
          #1c92d2
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Rockjaw"] {
        background: #f79d00; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #64f38c,
          #f79d00
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #64f38c,
          #f79d00
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Orbitox"] {
        background: #ad5389; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #3c1053,
          #ad5389
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #3c1053,
          #ad5389
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Warsnap"] {
        background: #11998e; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #38ef7d,
          #11998e
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #38ef7d,
          #11998e
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Brainboom"] {
        background: #3a1c71; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #ffaf7b,
          #d76d77,
          #3a1c71
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #ffaf7b,
          #d76d77,
          #3a1c71
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-image[hero="Pantaro"] {
        background: #bdc3c7; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #2c3e50,
          #bdc3c7
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #2c3e50,
          #bdc3c7
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      card-power {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 3px;
      }

      card-power[hero="Blazagon"] {
        color: #ff416c;
      }

      card-power[hero="Tyro"] {
        color: #1c92d2;
      }

      card-power[hero="Rockjaw"] {
        color: #f79d00;
      }

      card-power[hero="Orbitox"] {
        color: #3c1053;
      }

      card-power[hero="Warsnap"] {
        color: #11998e;
      }

      card-power[hero="Brainboom"] {
        color: #3a1c71;
      }

      card-power[hero="Pantaro"] {
        color: #2c3e50;
      }

      hero-name {
        display: block;
        font-size: 26px;
        color: black;
        font-weight: 900;
        margin-bottom: 24px;
      }

      [hidden] {
        display: none;
      }
    `,
  ];

  render() {
    return html`
      <card-shell>
        <card-image hero=${this.hero.name}>
          <img src=${this.hero?.image} alt="${this.hero.name}" />
        </card-image>
        <card-power hero="${this.hero.name}">
          Level ${this.hero.power}
        </card-power>
        <hero-name>${this.hero?.name}</hero-name>
      </card-shell>
    `;
  }
}

customElements.define("hero-adventure-card", HeroAdventureCard);
