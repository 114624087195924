import { html, css, LitElement } from "lit";
import "./planet-item";

export default class PlanetScreen extends LitElement {
  static properties = {
    hero: { type: Object },
    planets: { type: Array },
    setSelectedPlanet: { type: Object },
  };

  static styles = [
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      h1 {
        position: absolute;
        left: 127px;
        top: 15px;
        font-size: 32px;
        color: #f5f5f5;
        font-family: "Archivo Black", sans-serif;
        padding: 0;
        margin: 0;
      }

      p {
        position: absolute;
        left: 127px;
        top: 55px;
        font-size: 22px;
        color: #f5f5f5;
        font-family: "Archivo Black", sans-serif;
        padding: 0;
        margin: 0;
      }

      img[hero] {
        position: absolute;
        left: -30px;
        top: -50px;
        height: 200px;
      }

      planet-grid {
        display: flex;
        overflow-x: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100vw;
      }

      @media (max-width: 768px) {
        h1 {
          font-size: 24px;
          top: 20px;
        }
        p {
          font-size: 16px;
          top: 50px;
        }
      }
    `,
  ];

  render() {
    return html`
      <h1>Heroes of Goo Jit Zu Adventure</h1>
      <p>Choose a planet</p>
      <img hero src=${this.hero?.image} alt="${this.hero.name}" />
      <planet-grid>
        ${this.planets?.map((planet) => {
          return html`
            <planet-item
              @click=${() => this.setSelectedPlanet(planet)}
              .image=${planet.image}
            ></planet-item>
          `;
        })}
      </planet-grid>
    `;
  }
}

customElements.define("planet-screen", PlanetScreen);
