import { html, css, LitElement } from "lit";
import "@material/mwc-ripple";

export default class AppButton extends LitElement {
  static properties = {
    label: { type: String },
    ripple: { type: Object, state: true },
  };

  async firstUpdated() {
    this.ripple = this.shadowRoot?.querySelector("mwc-ripple");
    console.log(this.ripple);
  }

  static styles = [
    css`
      button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25vw;
        height: 100px;
        margin: 24px;
        border: 2px solid #f5f5f5;
        border-radius: 32px;
        font-size: 24px;
        color: #f5f5f5;
        font-family: "Archivo Black", sans-serif;
        --mdc-ripple-color: #000;
        overflow: hidden;
        background: #11998e; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #38ef7d,
          #11998e
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #38ef7d,
          #11998e
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }

      @media (max-width: 1300px) {
        button {
          height: 55px;
          font-size: 20px;
        }
      }

      @media (max-width: 1000px) {
        button {
          height: 45px;
          font-size: 18px;
        }
      }
    `,
  ];

  render() {
    return html`
      <button
        @mouseenter=${() => this.ripple.startHover()}
        @mouseleave=${() => this.ripple.endHover()}
        @focus=${() => this.ripple.startFocus()}
        @blur=${() => this.ripple.endFocus()}
        @mousedown=${(e) => this.ripple.startPress(e)}
        @mouseup=${() => this.ripple.endPress()}
        @keydown=${(e) => (e.which === 32 ? this.ripple.startPress() : "")}
        @keyup=${() => this.ripple.endPress()}
        ?disabled=${this.disabled}
      >
        <label>${this.label}</label>
        <mwc-ripple primary></mwc-ripple>
      </button>
    `;
  }
}

customElements.define("app-button", AppButton);
