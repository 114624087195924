import { html, css, LitElement } from "lit";
import "@material/mwc-ripple";

export default class PlanetItem extends LitElement {
  static properties = {
    image: { type: String },
    ripple: { type: Object, state: true },
  };

  async firstUpdated() {
    this.ripple = this.shadowRoot?.querySelector("mwc-ripple");
  }

  static styles = [
    css`
      :host {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        --mdc-ripple-color: #000;
        border-radius: 100%;
        min-width: 240px;
        width: 240px;
        height: 240px;
        padding: 50px;
        overflow: hidden;
      }
    `,
  ];

  render() {
    return html`
      <img
        @mouseenter=${() => this.ripple.startHover()}
        @mouseleave=${() => this.ripple.endHover()}
        @focus=${() => this.ripple.startFocus()}
        @blur=${() => this.ripple.endFocus()}
        @mousedown=${(e) => this.ripple.startPress(e)}
        @mouseup=${() => this.ripple.endPress()}
        @keydown=${(e) => (e.which === 32 ? this.ripple.startPress() : "")}
        @keyup=${() => this.ripple.endPress()}
        ?disabled=${this.disabled}
        @dragstart=${(e) => e.preventDefault()}
        src=${this.image}
      />
      <mwc-ripple primary></mwc-ripple>
    `;
  }
}

customElements.define("planet-item", PlanetItem);
