import { html, css, LitElement, nothing } from "lit";
import background from "../../assets/bg.png";
import firebackground from "../../assets/bg2.png";
import space from "../../assets/bg5.png";
import blazagon from "../../assets/blazagon.png";
import tyro from "../../assets/tyro.png";
import rockjaw from "../../assets/rockjaw.png";
import orbitox from "../../assets/orbitox.png";
import warsnap from "../../assets/warsnap.png";
import brainboom from "../../assets/brainboom.png";
import pantaro from "../../assets/pantaro.png";
import gigatusk from "../../assets/gigatusk.png";
import lavaplanet from "../../assets/lavaplanet.png";
import iceplanet from "../../assets/iceplanet.png";
import icebackground from "../../assets/bg3.png";
import explorationSigil from "../../assets/Sigils/6.png";
import orbitoxSigil from "../../assets/Sigils/12.png";
import heroSigil from "../../assets/Sigils/7.png";
import tyroSigil from "../../assets/Sigils/10.png";
import battleSigil from "../../assets/Sigils/8.png";

import "./app-button";
import "./champion-card";
import "./planet-screen";
import "./adventure-screen";
import "./battle-screen";

export default class AppGame extends LitElement {
  static properties = {
    screen: { type: String, state: true },
    heroes: { type: Array, state: true },
    enemies: { type: Array, state: true },
    selectedHero: { type: Object, state: true },
    selectedPlanet: { type: String, state: true },
    selectedBattle: { type: String, state: true },
  };

  constructor() {
    super();
    this.screen = "title";

    this.heroes = [
      {
        name: "Blazagon",
        abilities: [{ name: "Chomp Attack" }, { name: "Lava Slam" }],
        power: 1,
        image: blazagon,
        description:
          "A born Leader, when things heat up Blazagon knows how to take control. He uses his smarts before he uses his fists! ",
      },
      {
        name: "Tyro",
        abilities: [{ name: "Tyranno Chomp" }, { name: "Punch" }],
        power: 1,
        image: tyro,
        description:
          "Tyro is a powerful T-rex who is the leader of the Dino Squad and is a good friend to Blazagon.",
      },
      {
        name: "Orbitox",
        abilities: [{ name: "Tyranno Chomp" }, { name: "Punch" }],
        power: 1,
        image: orbitox,
        description:
          "Tyro is a powerful T-rex who is the leader of the Dino Squad and is a good friend to Blazagon.",
      },
      {
        name: "Warsnap",
        abilities: [{ name: "Alien Goo" }, { name: "Double Punch" }],
        power: 1,
        image: warsnap,
        description:
          "Warsnap is an alien affiliated with the Goo Jit Zu Brothers who has an inkling for war.",
      },
      {
        name: "Pantaro",
        abilities: [{ name: "Goo Jit Zu" }, { name: "Double Punch" }],
        power: 1,
        image: pantaro,
        description:
          "Pantaro is an alien affiliated with the Goo Jit Zu Brothers who has an inkling for war.",
      },
      {
        name: "Gigatusk",
        abilities: [{ name: "Tusk Smash" }, { name: "Giga jump" }],
        power: 1,
        image: gigatusk,
        description:
          "Gigatusk is an alient affiliated with the Goo Jit Zu Brothers who has an inkling for war.",
      },
      {
        name: "Rockjaw",
        abilities: [{ name: "Chomp" }, { name: "Claw Smash" }],
        power: 1,
        image: rockjaw,
        description:
          "Rockjaw is a fierce warrior ready to bring the pain on the battlefield. Watch out for his nasty claw.",
      },
    ];
    this.enemies = [
      {
        name: "Brainboom",
        abilities: [{ name: "Alien Goo" }, { name: "Double Punch" }],
        power: 1,
        image: brainboom,
        description:
          "Brainboom is an alient affiliated with the Goo Jit Zu Brothers who has an inkling for war.",
      },
      {
        name: "Rockjaw",
        abilities: [{ name: "Chomp" }, { name: "Claw Smash" }],
        power: 1,
        image: rockjaw,
        description:
          "Rockjaw is a fierce warrior ready to bring the pain on the battlefield. Watch out for his nasty claw.",
      },
    ];
    this.planets = [
      {
        name: "Lava World",
        image: lavaplanet,
        events: [
          {
            name: "Battle Rockjaw",
            type: "battle",
            enemy: this.enemies.find((enemy) => enemy.name === "Rockjaw"),
            image: battleSigil,
            completed: false,
          },
          {
            name: "Battle Tyro",
            type: "battle",
            enemy: this.heroes.find((hero) => hero.name === "Tyro"),
            image: tyroSigil,
            completed: false,
          },
          {
            name: "Battle Brainboom",
            type: "battle",
            enemy: this.enemies.find((enemy) => enemy.name === "Brainboom"),
            image: battleSigil,
            completed: false,
          },
        ],
      },
      {
        name: "Ice World",
        image: iceplanet,
        events: [
          {
            name: "Battle Rockjaw",
            type: "battle",
            enemy: this.enemies.find((enemy) => enemy.name === "Rockjaw"),
            image: battleSigil,
            completed: false,
          },
          {
            name: "Battle Gigatusk",
            type: "battle",
            enemy: this.heroes.find((hero) => hero.name === "Gigatusk"),
            image: heroSigil,
            completed: false,
          },
          {
            name: "Battle Orbitox",
            type: "battle",
            enemy: this.heroes.find((hero) => hero.name === "Orbitox"),
            image: orbitoxSigil,
            completed: false,
          },
          {
            name: "Battle Brainboom",
            type: "battle",
            enemy: this.enemies.find((enemy) => enemy.name === "Brainboom"),
            image: battleSigil,
            completed: false,
          },
        ],
      },
    ];

    // this.selectedHero = this.heroes?.[0];
    // this.selectedPlanet = this.planets?.[0];
  }

  firstUpdated() {
    this.addEventListener("reset-game", () => {
      this.selectedHero = null;
      this.selectedPlanet = null;
      this.selectedBattle = null;
      this.screen = "title";
    });
  }

  changeScreen(screen) {
    setTimeout(() => {
      this.screen = screen;
    }, 250);
  }

  setSelectedHero(hero) {
    this.selectedHero = hero;
    this.changeScreen("planet-screen");
  }

  setSelectedPlanet(planet) {
    this.selectedPlanet = planet;
    this.changeScreen("adventure-screen");
  }

  setSelectedBattle(battle) {
    this.selectedBattle = battle;
    this.changeScreen("battle-screen");
  }

  static styles = [
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      background-container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        min-height: 100vh;
        background-size: cover;
      }

      champion-select-screen,
      title-screen {
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        flex: 1 1 auto;
      }

      h1 {
        margin-top: 0;
        font-size: 10vh;
        color: #f5f5f5;
        text-align: center;
        font-family: "Archivo Black", sans-serif;
      }

      champion-select-screen h1 {
        position: absolute;
        top: 10vh;
      }

      card-container {
        display: flex;
        justify-content: center;
        flex: 1 1 auto;
      }

      animated-screen {
        position: fixed;
        inset: 0;
        display: flex;
      }

      animated-screen:not([show]) {
        opacity: 0;
        pointer-events: none;
      }

      animated-screen[show] {
        transition: 0.5s ease;
        opacity: 1;
      }

      @media (max-width: 1000px) {
        champion-select-screen h1 {
          top: 12px;
          color: #fff;
        }

        h1 {
          margin-top: 0px;
          font-size: 32px;
        }
      }
    `,
  ];

  render() {
    return html`
      <background-container
        style="background-image: url(${this.screen === "planet-screen"
          ? space
          : this.screen === "adventure-screen" ||
            this.screen === "battle-screen"
          ? this.selectedPlanet?.name === "Ice World"
            ? icebackground
            : firebackground
          : background})"
      >
        <animated-screen ?show=${this.screen === "title"}>
          <title-screen>
            <h1>Heroes of Goo Jit Zu</h1>
            <app-button
              @click=${() => this.changeScreen("champion-select")}
              label="PLAY"
            ></app-button>
          </title-screen>
        </animated-screen>

        <animated-screen ?show=${this.screen === "champion-select"}>
          <champion-select-screen>
            <h1>Select Your Hero</h1>
            <card-container>
              ${this.heroes?.map((hero) => {
                return html`
                  <champion-card
                    .setSelectedHero=${this.setSelectedHero.bind(this)}
                    .champion=${hero}
                  >
                  </champion-card>
                `;
              })}
            </card-container>
          </champion-select-screen>
        </animated-screen>

        <animated-screen ?show=${this.screen === "planet-screen"}>
          <planet-screen
            .setSelectedPlanet=${this.setSelectedPlanet.bind(this)}
            .hero=${this.selectedHero}
            .planets=${this.planets}
          ></planet-screen>
        </animated-screen>

        <animated-screen ?show=${this.screen === "adventure-screen"}>
          <adventure-screen
            .setSelectedBattle=${this.setSelectedBattle.bind(this)}
            .planet=${this.selectedPlanet}
            .hero=${this.selectedHero}
            .isActive=${this.screen === "adventure-screen"}
          ></adventure-screen>
        </animated-screen>

        <animated-screen ?show=${this.screen === "battle-screen"}>
          <battle-screen
            .hero=${this.selectedHero}
            .battle=${this.selectedBattle}
            .isActive=${this.screen === "battle-screen"}
          ></battle-screen>
        </animated-screen>
      </background-container>
    `;
  }
}

customElements.define("app-game", AppGame);
