import { html, css, LitElement } from "lit";
import "./adventure-battle";
export default class AdventureScreen extends LitElement {
  static properties = {
    hero: { type: Object },
    planet: { type: String },
    setSelectedBattle: { type: Object },
    isActive: { type: Boolean },
  };

  static styles = [
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      h1 {
        position: absolute;
        left: 24px;
        top: 15px;
        font-size: 32px;
        color: #f5f5f5;
        font-family: "Archivo Black", sans-serif;
        padding: 0;
        margin: 0;
      }

      p {
        position: absolute;
        left: 24px;
        top: 55px;
        font-size: 22px;
        color: #f5f5f5;
        font-family: "Archivo Black", sans-serif;
        padding: 0;
        margin: 0;
      }

      battle-grid {
        display: flex;
        overflow-x: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        padding: 150px;
        transform: translate(-50%, -50%);
        max-width: 100vw;
      }

      @media (max-width: 768px) {
        h1 {
          font-size: 24px;
          top: 20px;
        }
        p {
          font-size: 16px;
          top: 50px;
        }
      }
    `,
  ];

  render() {
    return html`
      <h1>${this.planet.name}</h1>
      <p>Goo Jit Zu Adventure</p>
      <battle-grid>
        ${this.planet?.events?.map((event) => {
          return html`
            <adventure-battle
              .setSelectedBattle=${this.setSelectedBattle.bind(this)}
              .event=${event}
            ></adventure-battle>
          `;
        })}
      </battle-grid>
    `;
  }
}

customElements.define("adventure-screen", AdventureScreen);
